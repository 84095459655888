<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h5>Tipo de cambio Venta</h5>
    <h3 class="mb-75 mt-2 pt-50">
      <b-form-input
          type="number"
        id="basicInput"
        v-model="data.value"
        placeholder="3.44"
      />
    </h3>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="updateExchangeRate"
    >
      Actualizar
    </b-button>
<!--    <b-button-->
<!--        variant="primary"-->
<!--        disabled-->
<!--        class="mr-1"-->
<!--    >-->
<!--      <b-spinner small />-->
<!--      Cargando...-->
<!--    </b-button>-->
    <!--    <b-img-->
    <!--      :src="require('@/assets/images/illustration/badge.svg')"-->
    <!--      class="congratulation-medal"-->
    <!--      alt="Medal Pic"-->
    <!--    />-->
  </b-card>
</template>

<script>
import {
  BCard, BButton, BFormInput,BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import settingStoreModule from '@/views/dramox/setting/settingStoreModule'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const toast = useToast()

    const APP_STORE_MODULE_NAME = 'app-settings'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, settingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const updateExchangeRate = () => {
      store.dispatch('app-settings/updateExchangeRate', props.data)
        .then(() => {
          showNotification({ title: 'Tipo de cambio actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(error => {
          console.log('error', error)
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      updateExchangeRate,
    }
  },
  // methods: {
  //   async updateExchangeRate() {
  //
  //     try {
  //       console.log(this.data)
  //
  //     }catch (e) {
  //       console.log(e)
  //     }
  //   },
  // },
}
</script>
