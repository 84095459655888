<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <home-greet />
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
        <home-exchange-rate
          v-if="userData.role === 'administrador'"
          :data="exchange_rate"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <home-statistics
          v-if="statisticsItems.length > 0"
          :data="statisticsItems"
        />
        <loading-empty-content v-else />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <h2>Comisiones</h2>
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            v-for="(item,index) in commissionSalesCompare['commission']"
            :key="index"
            lg="4"
            md="4"
            cols="12"
          >
            <home-commission
              icon="DollarSignIcon"
              :statistic-pen="item.value_pen"
              :statistic-usd="item.value_usd"
              :statistic-title="item.month"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12">
        <h2>Comparar comisiones <small>(Últimos dos meses)</small></h2>
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            v-for="item in commissionSalesCompare['commission_compare']"
            :key="item.title"
            lg="4"
            md="4"
            cols="12"
          >
            <home-commission
              icon="DollarSignIcon"
              :statistic-pen="item.value_pen"
              :statistic-usd="item.value_usd"
              :statistic-title="item.month"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12">
        <h2>Comparar ventas <small>(Últimos dos meses)</small></h2>
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            v-for="item in commissionSalesCompare['sales_compare']"
            :key="item.title"
            lg="4"
            md="4"
            cols="12"
          >
            <home-commission
              icon="DollarSignIcon"
              :statistic-pen="item.value_pen"
              :statistic-usd="item.value_usd"
              :statistic-title="item.month"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import LoadingEmptyContent from '@core/components/loading/LoadingEmptyContent'
import HomeExchangeRate from './components/HomeExchangeRate.vue'
import HomeCommission from './components/HomeCommission.vue'
import HomeStatistics from './components/HomeStatistics.vue'
import HomeGreet from './components/HomeGreet.vue'

export default {
  components: {
    LoadingEmptyContent,
    HomeGreet,
    HomeCommission,
    HomeExchangeRate,
    BRow,
    BCol,
    HomeStatistics,
  },
  data() {
    return {
      statisticsItems: [],
      userData: getUserData(),
      data: {
      },
      exchange_rate: {
        value: 0,
      },
      commissionSalesCompare: {
        commission: [
          {
            title: '---',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
          {
            title: '----',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
          {
            title: '-----',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
        ],
        commission_compare: [
          {
            title: '---',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
          {
            title: '----',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
          {
            title: '-----',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
        ],
        sales_compare: [
          {
            title: '---',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
          {
            title: '----',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
          {
            title: '-----',
            value_pen: 'S/00.00',
            value_usd: '$00.00',
          },
        ],
      },
    }
  },
  async created() {
    const response = await this.$http.get('admin/dashboard')
    this.statisticsItems = response.data.payload.results.statistics
    this.exchange_rate.value = response.data.payload.results.exchange_rate
    this.commissionSalesCompare = response.data.payload.results.commission_sale_compare
    this.checkSupportPushMessaging()
  },
  methods: {
    checkSupportPushMessaging() {
      if ('Notification' in window && 'serviceWorker' in navigator) {
        console.log('Notification and serviceWorker is supported')
        this.askPermission()
      }
    },
    askPermission() {
      console.log(this.userData.id)
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.')
          navigator.serviceWorker.ready.then(registration => {
            registration.pushManager.getSubscription().then(subscription => {
              if (subscription) {
                // this.$store.dispatch('auth/updatePushSubscription', subscription)
                console.log('subscription exists', subscription)
                console.log('subscription show', JSON.stringify(subscription))
                this.$http.post('admin/add-subscription', {
                  subscription,
                  userId: this.userData.id,
                })
              } else {
                this.subscribeUserToPush(registration).then(pushSubscription => {
                  console.log('pushSubscription user', pushSubscription)
                  console.log('pushSubscription user data', JSON.stringify(pushSubscription))
                  this.$http.post('admin/add-subscription', {
                    subscription: pushSubscription,
                    userId: this.userData.id,
                  })
                })
              }
            })
          })
        }
      })
    },
    subscribeUserToPush(registration) {
      const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY
      const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)
      return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidPublicKey,
      })
    },
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
      const base64 = (base64String + padding)
      // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, '+')
        .replace(/_/g, '/')
      const rawData = window.atob(base64)
      const outputArray = new Uint8Array(rawData.length)
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
